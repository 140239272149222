<template>
  <div class="login">
    <div class="toubu">
      <div class="toubu_z pointer" @click="goGateway">
        “穗智转” 公共服务平台 | 欢迎登录
        <!-- 中小企业数字化转型试点中心 | 欢迎登录 -->
      </div>
    </div>
    <div class="login_box" :style="loginBoxImg">
      <div class="login_k">
        <div class="login_k_l">
          <div class="login_k_l_top">
            <div
              class="login_k_l_top_mei"
              v-for="(item, index) in identityList"
              :key="index"
            >
              <a
                :class="item.isSelected ? 'is_select' : ''"
                href="javascript:;"
                @click="changeSelectedItem(index)"
                >{{ item.selectName }}</a
              >
            </div>
          </div>
          <div>
            <div class="login-form">
              <el-form
                label-position="top"
                label-width="80px"
                :model="loginInfo"
              >
                <el-form-item label="用户名">
                  <el-input v-model="loginInfo.account"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                  <el-input
                    v-model="loginInfo.password"
                    type="password"
                  ></el-input>
                </el-form-item>
                <!-- 临时修改 2 开始 - 注释 -->
                <el-form-item label="验证码">
                  <el-input v-model="loginInfo.captcha"></el-input>
                </el-form-item>
                <el-form-item>
                  <img
                    class="codeImg"
                    ref="codeImg"
                    :src="codeImg"
                    @click="getCode"
                  />
                </el-form-item>
                <!-- 临时修改 2 结束 -->
                <!-- 临时修改 2 开始 - 增加标签 -->
                <div style="height: 30px"></div>
                <!-- 临时修改 2 结束 -->

                <el-form-item style="text-align: center">
                  <el-button class="primaryBtn" @click="handleLogin"
                    >登录</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div class="zhuce">
              <a
                id="register"
                class="pointer"
                href="javascript:;"
                @click="goRegister()"
                v-if="is_stu == 2 || is_stu == 3"
              >
                立即注册
              </a>
              <a
                id="entry"
                class="pointer"
                style="margin-top: 10px"
                href="javascript:;"
                @click="goGateway"
                v-if="is_stu == 3"
              >
                立即进入
              </a>
            </div>
            <div style="margin: 10px 0; text-align: center">
              <a
                id="forgetPwd"
                class="pointer"
                href="javascript:;"
                @click="showForgetPwdDialog"
                style="font-size: 14px"
              >
                忘记密码
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="marker" v-show="isShowForgetPwdDialog"></div>
    <div
      class="forgetPwdDialog"
      v-show="isShowForgetPwdDialog"
      :style="forgetPwdDialogImg"
    >
      <div class="forgetPwdTitle">
        忘记密码
        <img class="callbackImg" :src="'/callback.png' | staticMedia" alt="" />
        <img
          class="pointer"
          @click="hideForgetPwdDialog"
          id="closeImg"
          :src="'/close.webp' | staticMedia"
        />
      </div>
      <div class="forgetPwdBody scroll-style">
        <div class="forgetPwdItem">
          <label for="phone"><span style="color: red">*</span>手机号</label>
          <input type="text" ref="phone" placeholder="请输入手机号码" />
          <button
            ref="getSmsBtn"
            type="button"
            class="smscodeBtn"
            @click="getSmscode"
          >
            获取验证码
          </button>
        </div>
        <div class="forgetPwdItem">
          <label for="sms_code"><span style="color: red">*</span>验证码</label>
          <input type="text" ref="sms_code" placeholder="请输入验证码" />
        </div>
        <div class="forgetPwdItem">
          <label for="password"><span style="color: red">*</span>新密码</label>
          <input type="password" ref="password" placeholder="请输入新密码" />
        </div>
        <div class="Butg flex horizontally">
          <el-button class="primaryBtn1" @click="handleChangePwd">
            提交
          </el-button>
          <el-button class="cancleBtn1" @click="hideForgetPwdDialog"
            >取消</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { checkNull } from "@/utils/common.js";
import { login, getSmsCode, forgetPwd } from "@/api/login.js";
import configs from "@/config/index";
import { getCompanyInfo } from "@/api/pts";

export default {
  name: "Login",
  data() {
    return {
      identityList: [
        {
          selectName: "政府",
          isSelected: true,
          is_stu: 1,
        },
        {
          selectName: "平台商",
          isSelected: false,
          is_stu: 2,
        },
        {
          selectName: "企业",
          isSelected: false,
          is_stu: 3,
        },
      ],
      is_stu: 1,
      loginInfo: {
        account: "",
        password: "",
        captcha: "",
      },
      isShowForgetPwdDialog: false,
      codeImg: "",
      timer: null,
      random: "",
    };
  },
  computed: {
    forgetPwdDialogImg() {
      const filteredImageUrl = this.$options.filters.staticMedia(
        "/yhfk_bg.webp"
      );
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
    loginBoxImg() {
      const filteredImageUrl = this.$options.filters.staticMedia(
        "/login_bg.png"
      );
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
  },
  mounted() {
    this.getCode();
    // 进入登录页需要清空session、cookie，避免出现存值为undefined的报错
    sessionStorage.clear();

    this.$cookie.set("SZZ_TOKEN", null);
    this.$cookie.set("SZZ_STU", null);
  },
  methods: {
    randomString(length) {
      var str =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var result = "";
      for (var i = length; i > 0; --i)
        result += str[Math.floor(Math.random() * str.length)];
      return result;
    },
    handleChangePwd() {
      let _this = this;
      if (
        checkNull(_this.$refs.phone.value, "手机号不能为空！") &&
        checkNull(_this.$refs.sms_code.value, "验证码不能为空！") &&
        checkNull(_this.$refs.password.value, "密码不能为空！")
      ) {
        const regex = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,30}$/;
        if (!regex.test(_this.$refs.password.value)) {
          _this.$message.error(
            "请输入大写字母、小写字母、数字、字符，包含其中三项的6-30位强密码！"
          );
          return false;
        }
        let pdata = {
          sms_code: _this.$refs.sms_code.value,
          phone: _this.$refs.phone.value,
          password: _this.$refs.password.value,
        };
        forgetPwd(pdata)
          .then((res) => {
            console.log("res", res);
            if (res.code === 1) {
              this.$message.success(res.msg);
            }
            setTimeout(() => {
              _this.hideForgetPwdDialog();
            }, 1500);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    getSmscode() {
      let _this = this;
      _this.$refs.getSmsBtn.disabled = true;
      _this.$refs.getSmsBtn.style.cursor = "not-allowed";

      let pdata = {
        phone: _this.$refs.phone.value,
      };
      getSmsCode(pdata)
        .then((res) => {
          console.log("res", res);
          if (res.code === 1) {
            _this.$message.success(res.msg);
            _this.countDown();
          }
        })
        .catch((err) => {
          _this.countDown();
          console.log("err", err);
        })
        .finally(() => {
          _this.timer = null;
        });
    },
    countDown() {
      let _this = this;
      let secondsRemaining = 1;
      window.clearInterval(_this.timer);
      // _this.timer = null;
      if (!_this.timer) {
        _this.timer = setInterval(function() {
          if (secondsRemaining >= 0) {
            _this.$refs.getSmsBtn.textContent =
              secondsRemaining-- + "秒后重新获取";
            // window.clearInterval(_this.timer);
          } else if (secondsRemaining < 0) {
            window.clearInterval(_this.timer);
            _this.$refs.getSmsBtn.textContent = "重新获取验证码";
            _this.$refs.getSmsBtn.disabled = false;
            _this.$refs.getSmsBtn.style.cursor = "pointer";
          }
        }, 1000);
      } else {
        window.clearInterval(_this.timer);
        _this.timer = null;
      }
    },
    hideForgetPwdDialog() {
      this.isShowForgetPwdDialog = false;
    },
    showForgetPwdDialog() {
      this.isShowForgetPwdDialog = true;
    },
    changeSelectedItem(index) {
      this.identityList.forEach((e) => {
        e.isSelected = false;
      });
      this.identityList[index].isSelected = true;
      this.is_stu = this.identityList[index].is_stu;

      // if (this.is_stu === 2) {
      //   this.loginInfo.account = "tansy001";
      //   this.loginInfo.password = "tansy001.";
      // } else if (this.is_stu === 3) {
      //   this.loginInfo.account = "tansy0921";
      //   this.loginInfo.password = "@tansy0921";
      // } else if (this.is_stu === 1) {
      //   this.loginInfo.account = "tansy002";
      //   this.loginInfo.password = "tansy002.";
      // }
    },
    getCode() {
      this.random = this.randomString(10);
      console.log(this.random);
      // this.codeImg = `${configs.baseUrl}/index/user/getCaptcha?r=${this.random}&captcha_str=${configs.randomStr}`;
      this.codeImg = `${configs.baseUrl}/index/user/getCaptcha?captcha_str=${this.random}`;
    },
    handleLogin() {
      if (
        checkNull(this.loginInfo.captcha, "验证码不能为空！") &&
        checkNull(this.loginInfo.account, "用户名不能为空！") &&
        checkNull(this.loginInfo.password, "密码不能为空！")
      ) {
        let pdata = {
          account: this.loginInfo.account,
          password: this.loginInfo.password,
          captcha: this.loginInfo.captcha,
          is_auth: this.is_stu,
          // captcha_str: configs.randomStr,
          captcha_str: this.random,
        };

        login(pdata)
          .then((res) => {
            if (res.code == 1) {
              this.$message.success("登录成功！");
              // 过期时间
              let timetemp = new Date().getTime() + 1000 * 60 * 60 * 2;
              // let timetemp = new Date().getTime() + 1000 * 60 * 7;
              sessionStorage.setItem("szz_v1_token_expiration_time", timetemp);
              // 用户身份，直接拿res的内容，子账号为4
              let is_stu = String(
                res.data.user.stu || res.data.user.is_stu
              ).trim();
              sessionStorage.setItem("is_stu", is_stu);
              sessionStorage.setItem("user", JSON.stringify(res.data.user));
              sessionStorage.setItem("token", res.data.token);
              localStorage.setItem("token_", res.data.token);
              this.$cookie.set("SZZ_TOKEN", res.data.token, {
                expires: timetemp,
              });
              this.$cookie.set("SZZ_STU", res.data.user.is_stu, {
                expires: timetemp,
              });
              // 政府登录
              if (is_stu == 1) {
                sessionStorage.setItem("menuIndex", 2);
                sessionStorage.setItem("navName", "新闻动态");
                this.$store.commit("SET_NAV_NAME", "新闻动态");
                setTimeout(() => {
                  this.$router.push({
                    path: "/government/index",
                  });
                }, 3000);
                // 平台商登录
              } else if (is_stu == 2) {
                sessionStorage.setItem("menuIndex", 3);
                getCompanyInfo().then((res) => {
                  sessionStorage.setItem("ptsInfo", JSON.stringify(res.data));
                });
                setTimeout(() => {
                  this.$router.push({
                    path: "/company/product",
                  });
                }, 3000);
                // 子平台商登录
              } else if (is_stu == 4) {
                sessionStorage.setItem("menuIndex", 0);
                getCompanyInfo().then((res) => {
                  sessionStorage.setItem("ptsInfo", JSON.stringify(res.data));
                });
                setTimeout(() => {
                  this.$router.push({
                    path: "/company/diagnose",
                  });
                }, 3000);
                // 企业登录
              } else if (is_stu == 3) {
                sessionStorage.setItem("menuIndex", 0);
                setTimeout(() => {
                  this.$router.push({
                    // 临时修改 2
                    path: "/requirePublic",
                    // path: "/siHuaQuestion",
                  });
                }, 3000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return false;
      }
    },
    goRegister() {
      this.$router.push({
        path: "/register",
        query: { is_stu: this.is_stu },
      });
    },
    goGateway() {
      // let gatewayUrl = location.protocol + "//" + location.hostname;
      // location.href = gatewayUrl;
      location.href = "https://www.suizz.nuxt.hcxtec.com";
    },
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/login.css";

.el-button + .el-button {
  margin-left: 30px;
}

.login-form {
  width: 78%;
  margin: 20px auto;

  .el-form--label-top .el-form-item__label {
    padding-bottom: 0;
  }

  .el-form-item {
    margin-bottom: 5px;
  }

  .el-input__inner {
    height: 48px;
    line-height: 48px;
    border-radius: 10px;
  }

  .codeImg {
    height: auto;
    width: 250px;
    background-color: #999;
  }

  .primaryBtn {
    height: 42px;
    width: 160px;
  }
}

.forgetPwdDialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  width: 663px;
  height: 491px;

  background-size: 100% 100%;
  background-repeat: no-repeat;

  padding: 0 30px 30px;
  z-index: 999;
}

.forgetPwdTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  line-height: 100px;
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  padding: 0 0 0 70px;
  position: relative;
}

.forgetPwdTitle img {
  height: 21px;
  width: 21px;
}

.forgetPwdTitle .callbackImg {
  height: 138px;
  width: 138px;
  position: absolute;
  top: 12px;
  right: 54px;
  z-index: 999;
}

.forgetPwdBody {
  border-radius: 4px;
  background-color: #eff5ff;
  height: 391px;
  padding: 82px 55px;
  box-sizing: border-box;
  overflow-y: hidden;
}

.forgetPwdItem {
  margin-bottom: 20px;
  line-height: 42px;
  display: flex;
  position: relative;
}

.forgetPwdItem label {
  display: inline-block;
  text-align: right;
  width: 80px;
  font-size: 14px;
  margin-right: 18px;
}

.forgetPwdItem input {
  height: 42px;
  width: 345px;
  border: 1px solid #aaa;
  padding-left: 5px;
  border-radius: 4px;
}

.forgetPwdItem input:focus-visible {
  outline: none;
}

.Butg {
  padding: 25px 60px;
}

.smscodeBtn {
  position: absolute;
  right: 103px;
  height: 44px;
  border: 1px solid #205dc3;
  background-color: #205dc3;
  color: #fff;
  padding: 0 13px;
  border-radius: 4px;
}
</style>
